import React from "react"
import Title from "./Title"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

const query = graphql`
  {
    allCustomProductsJson {
      nodes {
        id
        price
        image {
            childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, formats: AUTO, placeholder: BLURRED)
          }
        }
      }
    }
  }
`


const CustomProducts = () => {

 const { allCustomProductsJson: { nodes } } = useStaticQuery(query)


  return (
    <section className="section">
      <Title title="Customized Products" />
      <div className="section-center">
      <div className='events-grid'>
            {nodes.map((data) => {
                const {id, price, image} = data
                console.log(`products${image}`)
                return (
                    <div key={id} className='events-grid-items'>
                        <GatsbyImage
                            image={image.childImageSharp.gatsbyImageData}
                            alt={`product-image-${id}`}
                        />
                        <h4 className='product-price'>Price: {price}</h4>
                    </div>
                )
            })}
           
        </div>
        <div className='btn-div'>
              <Link to='/gallery'>
                <button className='btn-section'>Learn More</button>
              </Link>
                
        </div>
      </div>
           
    </section>
  )
}

export default CustomProducts
