import React from "react"
import Wedo from '../components/Wedo'
import Hero from "../components/Hero"
import OurEvents from '../components/OurEvents'
import CustomProducts from '../components/CustomProducts'
import ContactMap from '../components/ContactMap'
import Seo from "../components/Seo"

const IndexPage = () => {
  
  return (
    <>
      <Seo title="Home" />
      <main>
        <Hero />
        <Wedo />
        <OurEvents />
        <CustomProducts />
        <ContactMap />
      </main>
    </>
  )
}



export default IndexPage
