import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { GiPositionMarker } from "react-icons/gi";



const query = graphql`
  {
    staticMap {
      childFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: AUTO)
        }
      }
    }
  }
`

const ContactMap = () => {

    const { staticMap: { childFile } } = useStaticQuery(query)
    console.log(childFile)
    return (
        <section className='section'>
            <div className='section-center pink-bg-div map-grid'>
             
             <div className='map-grid-items form-items'>
                 <h3 className='form-map-title'>Stay In Touch</h3>
                 <form 
                 action="https://submit-form.com/RnZQcCew" 
                 method='POST'
                 >
                    <div className="form-group">

                    <input type="hidden" name="_feedback.whitelabel" value="true" />

                    <input
                      type="checkbox"
                      name="_honeypot"
                      style={{display: "none"}}
                      tabindex="-1"
                      autocomplete="off"
                    />

                    <input
                        type="text"
                        name="name"
                        placeholder="name"
                        className="form-control"
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="email"
                        className="form-control"
                        required
                    />
                    <textarea
                        name="message"
                        rows="5"
                        placeholder="message"
                        className="form-control"
                    ></textarea>
                    </div>
                    <button type="submit" className="map-btn">
                    submit here
                    </button>
                 </form>
             </div>

             <div className='map-grid-items map-items'>
                 <GatsbyImage image={childFile.childImageSharp.gatsbyImageData} />
                 <p className='p-map'><GiPositionMarker /> 9804 S. Lakewood Place Tulsa Oklahoma 74137</p>
             </div>
            </div>
            
        </section>
    )
}

export default ContactMap
