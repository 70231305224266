import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"


const query = graphql`
  {
    placeholderImage: file(relativePath: {eq: "hero-pose.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          layout: CONSTRAINED
          )
      }
    }
  }
`



const Hero = () => {

  const { placeholderImage } = useStaticQuery(query)
  
  const image = getImage(placeholderImage)

  // const bgImage = convertToBgImage(image)

  return (
    <header className='bridal-hero'>
     
      <BgImage 
      image={image} 
      style={{ minWidth: "100vw", minHeight: '100vh' }}
      preserveStackingContext={true}
      >
        <div className='text-hero'>
          <h1 className='hero-heading'><span className='hero-span'>Best Event Planner</span> That’s Within Your Budget.</h1>
          <Link to='/contact'>
          <button className='btn-hero'>Contact Us Now!</button>
          </Link>

        </div>
      </BgImage>
      
    </header>
  )
}

export default Hero
