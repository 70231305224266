import React from 'react'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Wedo = () => {
    return (
        <section className='section'>
            <div className='wedo-box section-center'>
            
                <div className="img-wedo grid-child">
                    <StaticImage 
                       src='../assets/images/wedo-image.jpg'
                       alt='we do image'
                       width={350}
                    //    height={600}
                       className='wedo-image-margin'
                       layout='constrained'
                       
                    />
                </div>

                <div className="info-wedo grid-child">
                    <h2 className='wedo-h2'>What We Do</h2>
                    <div className="underline-2"></div>
                    <p className='wedo-p'>We offer the best wedding and event planning from ceremonial to the reception, birthday party, debutants, any events you may have we carefully execute with considerations and utmost care as if it was our own. We also provide custom and ready to wear wedding gowns, evening gowns, party dresses, casual dresses, and accessories to complete the whole ensemble.
                    </p>
                    <Link to='/gallery'>
                    <button className='wedo-btn'>
                        Learn More
                    </button>
                    </Link>
                </div>
            </div>
        </section>
       
    )
}

export default Wedo
