import React from "react"
import Title from "./Title"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

const query = graphql`
  {
    allEventSectionJson {
      nodes {
        id
        image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, formats: AUTO, placeholder: BLURRED)
          }
        }
      }
    }
  }
`


const OurEvents = () => {

 const { allEventSectionJson: { nodes } } = useStaticQuery(query)
 

  return (
    <section className="section bg-grey">
      <Title title="Our Events" />
      <div className="section-center">
        <div className='events-grid'>
            {nodes.map((data) => {
                const {id, image} = data
                console.log(`events${image}`)
                return (
                    <div className='events-grid-items' key={id}>
                        <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={`events-image-${id}`}/>
                    </div>
                )
            })}
           
        </div>
        <div className='btn-div'>
                <Link to='/gallery'>
                  <button className='btn-section'>Learn More</button>
                </Link>
                
        </div>
      </div>
           
    </section>
  )
}

export default OurEvents
